import React from "react";
import { graphql } from "gatsby";
import SEO from "../components/seo";
import Layout from "../components/layout";
import Hero from "../components/hero";
import ReadMore from "../components/read-more";
import PostsList from "../components/posts/list";

export const query = graphql`
  query ErrorPageQuery {
    articles: allSanityArticle(
      sort: { fields: publishDate, order: DESC }
      limit: 3
    ) {
      nodes {
        ...articleTileFields
      }
    }
  }
`;

const Error404 = ({ data }) => {
  const articles = data.articles.nodes;
  return (
    <Layout>
      <SEO title="Page Not Found" />
      <Hero
        title="Page not found"
        description="The page you are trying to visit could not be found."
      ></Hero>
      <div className="container">
        {articles.length > 0 && (
          <section className="articles posts-container">
            <p>
              To get started on Flying Colours, here's some of the latest from
              us:
            </p>

            <PostsList
              posts={articles}
              includeFeatured={false}
              condensed={false}
              carousel={true}
            ></PostsList>
            <ReadMore
              text="View all the latest news"
              link="/content-finder/?type=article"
            ></ReadMore>
          </section>
        )}
      </div>
    </Layout>
  );
};

export default Error404;
